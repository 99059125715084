import Vue from 'vue';
import axios from 'axios';

import "core-js/stable";
import "regenerator-runtime/runtime";

new Vue({
  el: '#news',
  data: {
    topicsData: [],
    isNew: false,
    sevenDaysAgo: '',
    topicsDateList: []
  },
  methods: {
    formatDateToNumber(time) {
      if(time === '') return 0;
      const date = new Date(time);
      const result = date.getTime();
      return result;
    },
    async getTopicsData() {
      const res = await axios.get('https://s.actyprint.jp/api/topics');
      this.topicsData = res.data.data;
    }
  },
  async mounted() {
    await this.getTopicsData();
  }
});
